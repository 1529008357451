import React, { useState, useEffect } from "react";

import Project from "./project";
import "./styles/allProjects.css";
import { fetchData } from "../../fetchData";
import LoadingUI from "../common/loading";
import SomethingWentWrong from "../../pages/error";

const AllProjects = ({ projectsData }) => {
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [projectData, setProjectData] = useState([]);

    useEffect(() => {
        if (projectsData && projectsData.length > 0) {
            setProjectData(projectsData);
            setLoading(false);
        } else {
            fetchData("project")
                .then((data) => {
                    setProjectData(data.projects);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        }

    }, [projectsData]);

    if (isLoading) {
        return <LoadingUI active='projects' />;
    }

    if (error) {
        return <SomethingWentWrong error={error} />
    }


    return (
        <div className="all-projects-container">
            {projectData.map((project, index) => (
                <div className="all-projects-project" key={index}>
                    <Project
                        logo={project.display_image}
                        title={project.name}
                        description={project.short_description}
                        linkText="View Project"
                        link={`/project/${project.slug}`}
                    />
                </div>
            ))}
        </div>
    );
};

export default AllProjects;
