import React, { useState, useEffect } from "react";

import { fetchData } from "../../fetchData";
import LoadingUI from "../common/loading";
import SomethingWentWrong from "../../pages/error";
import RecommendationCard from "./recommendationCard";

const AllRecommendations = ({ recoData }) => {
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [recommendationData, setRecommendationData] = useState([]);

    useEffect(() => {
        if (recoData && recoData.length > 0) {
            setRecommendationData(recoData);
            setLoading(false);
        }
        else {
            fetchData("recommendations")
                .then((data) => {
                    setRecommendationData(data.recommendations);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        }

    }, [recoData])

    if (isLoading) {
        return <LoadingUI active='recommendations' />;
    }

    if (error) {
        return <SomethingWentWrong error={error} />
    }


    return (
        <div className="homepage-after-title">
            <div className="homepage-recommendations">

                {recommendationData.map((reco, index) => (
                    <div className="homepage-recommendation" key={index + 1}>
                        <RecommendationCard
                            date=""
                            title={reco.name}
                            job_name={reco.job_name}
                            job_title={reco.job_title}
                            description={`${reco.recommendation.substring(0, 500)}...`}
                            link={`/recommendations/${reco.slug}`}
                        />
                    </div>

                ))}
            </div>
        </div>
    );
};

export default AllRecommendations;
