export const API_URL = process.env.REACT_APP_API_URL;

function encodeToken(token) {
	const utcTime = Math.floor(Date.now() / 1000);
	const encodedToken = btoa(`${token}:${utcTime}`);
	return encodedToken;
}
export const getAuthorizationHeader = () => {
	const token = process.env.REACT_APP_SECRET_KEY;
	const encodedToken = encodeToken(token);
	return `Bearer ${encodedToken}`;
};

export const fetchData = async (endpoint) => {
	const url = `${API_URL}/api/${endpoint}`;

	const response = await fetch(url, {
		method: "GET", // or 'POST', etc.
		headers: {
			Authorization: getAuthorizationHeader(),
		},
	});
	if (!response.ok) {
		throw new Error("Network response was not ok");
	}

	return await response.json();
};

export const postData = async (endpoint, newData) => {
	const url = `${API_URL}/api/${endpoint}`;
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: getAuthorizationHeader(),
		},
		body: JSON.stringify(newData),
	});
	return response;
};
