import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    faGithub,
    faStackOverflow,
    faLinkedin

} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import AllProjects from "../components/projects/allProjects";
import AllRecommendations from "../components/recommendation/allRecommendation";
import INFO from "../data/user"; // Assuming this contains your personal info and social links
import SEO from "../data/seo"; // Assuming this contains SEO data
import "./styles/homepage.css";
import { API_URL, fetchData } from "../fetchData";
import SomethingWentWrong from "./error";
import LoadingUI from "../components/common/loading";


const Homepage = () => {
    const [stayLogo, setStayLogo] = useState(false);
    const [logoSize, setLogoSize] = useState(80);
    const [oldLogoSize, setOldLogoSize] = useState(80);
    const [homeData, setHomeData] = useState({
        home: {
            front_text: "",
            side_pic: "",
            show_img: false,
        },
    });
    const [projectData, setProjectData] = useState([]);
    const [recommendationData, setRecommendationData] = useState([]);
    const [resumeLink, setResumeLink] = useState([]);

    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDataAsync = async () => {
            try {
                const data = await fetchData("homepage");
                setHomeData(data.home);
                setResumeLink(data.resumeLink);
                setProjectData(data.projects)
                setRecommendationData(data.recommendations)
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        setLoading(true);
        fetchDataAsync();
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);

        const handleScroll = () => {
            let scroll = Math.round(window.pageYOffset, 2);
            let newLogoSize = 80 - (scroll * 4) / 10;

            if (newLogoSize < oldLogoSize) {
                if (newLogoSize > 40) {
                    setLogoSize(newLogoSize);
                    setOldLogoSize(newLogoSize);
                    setStayLogo(false);
                } else {
                    setStayLogo(true);
                }
            } else {
                setLogoSize(newLogoSize);
                setStayLogo(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [oldLogoSize]);


    const currentSEO = SEO.find((item) => item.page === "home");

    const logoStyle = {
        display: "flex",
        position: stayLogo ? "fixed" : "relative",
        top: stayLogo ? "3vh" : "auto",
        zIndex: 999,
        border: stayLogo ? "1px solid white" : "none",
        borderRadius: stayLogo ? "50%" : "none",
        boxShadow: stayLogo ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
    };

    if (isLoading) {
        return <LoadingUI active='home' />;
    }

    if (error) {
        return <SomethingWentWrong error={error} />
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{INFO.main.title}</title>
                <meta name="description" content={currentSEO.description} />
                <meta
                    name="keywords"
                    content={currentSEO.keywords.join(", ")}
                />
            </Helmet>

            <div className="page-content">
                <NavBar active="home" />
                <div className="content-wrapper">
                    <div className="homepage-logo-container">
                        <div style={logoStyle}>
                            <Logo width={logoSize} link={false} />
                        </div>
                    </div>

                    <div className="homepage-container">
                        <div className="homepage-first-area">
                            <div className="homepage-first-area-left-side">
                                <div className="title homepage-title">
                                    {INFO.homepage.title}
                                </div>

                                <div className="subtitle homepage-subtitle">
                                    {homeData.front_text.split('\n').map((text, index) => (
                                        <React.Fragment key={index}>
                                            {text}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </div>


                                <div className="homepage-socials">
                                    <a
                                        href={INFO.socials.linkedin}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon
                                            icon={faLinkedin}
                                            className="homepage-social-icon"
                                        />
                                    </a>
                                    <a
                                        href={INFO.socials.twitter}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon
                                            icon={faTwitter}
                                            className="homepage-social-icon"
                                        />
                                    </a>
                                    <a
                                        href={INFO.socials.github}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon
                                            icon={faGithub}
                                            className="homepage-social-icon"
                                        />
                                    </a>
                                    <a
                                        href={INFO.socials.stackoverflow}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon
                                            icon={faStackOverflow}
                                            className="homepage-social-icon"
                                        />
                                    </a>
                                    <a
                                        href={`mailto:${INFO.main.email}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon
                                            icon={faEnvelope}
                                            className="homepage-social-icon"
                                        />
                                    </a>

                                    <a
                                        href={`${resumeLink}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download="MehadiHassan_Resume.pdf"
                                    >
                                        <FontAwesomeIcon
                                            icon={faFileDownload}
                                            className="homepage-social-icon"
                                        />
                                    </a>
                                </div>
                            </div>

                            <div className="homepage-first-area-right-side">
                                {homeData.show_img && (
                                    <div className="homepage-image-container">
                                        <div className="homepage-image-wrapper">
                                            <img
                                                src={
                                                    `${API_URL}${homeData.side_pic}`
                                                }
                                                alt="Mehadi Hassan"
                                                className="homepage-image"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {!isLoading && ( // Only render when not loading
                            <div className="homepage-projects">
                                <AllProjects projectsData={projectData} />
                            </div>
                        )}

                        {!isLoading && ( // Only render when not loading
                            <div className="homepage-after-title">
                                <div className="homepage-recommendations">
                                    <AllRecommendations recoData={recommendationData} />
                                </div>
                            </div>
                        )}
                        <div className="page-footer">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
};

export default Homepage;
